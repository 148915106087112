import React, { useContext } from 'react';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor from 'react-scrollable-anchor';
import ContentBlock from 'components/Content/ContentBlock';
import BrandCTA from 'components/BrandCta';
import ColorBlock from 'components/Content/ColorBlock';

// import page style
import './about.scss';

// Images
import whatIsNasNasalSprayHCPHeroMobile from '../../../assets/images/NS-HCP-whatIsNasNasalSpray-mobile.png';
import nascobalSprayProduct from '../../../assets/images/nascobal-spray-label-right.png';

// PDFs

const AboutPage = () => {
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  // const metaData = appConfigs?.metaData?.hcp;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const pageTitle = 'NASCOBAL® | What is NASCOBAL®?';
  const pageDescription = 'The only FDA-approved vitamin B12 nasal spray';

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-about">
        <ContentBlock id="cblock-vitaminb12">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    <span className="yellow">
                      <span
                        className="brandname-nascobal"
                        dangerouslySetInnerHTML={{
                          __html: brandNames?.NASCOBAL,
                        }}
                      />
                    </span>{' '}
                    <br className="hide-only-mobile" />
                    Nasal Spray
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={whatIsNasNasalSprayHCPHeroMobile} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h2 className="strong txt-fda-approved">
                  The first FDA-approved prescription vitamin B
                  <sub className="vitaminb12-sub">12</sub> nasal spray
                  <sup>1</sup>
                </h2>
              </Col>
            </Row>

            <ColorBlock lightorange orangeborder>
              <Row>
                <Col xs={12} md={9}>
                  <p className="blue">
                    <strong>
                      For appropriate patients with vitamin B
                      <sub className="vitaminb12-sub">12</sub> deficiency
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Designed to bypass the gastrointestinal (GI) tract and be
                      absorbed directly into the bloodstream<sup>2</sup>
                    </li>
                    <li>
                      Clinically proven to have increased vitamin B
                      <sub className="vitaminb12-sub">12</sub> levels from the
                      first dose<sup>3</sup>
                    </li>
                    <li>
                      Convenient, once-weekly dosing regimen
                      <sup>4</sup>*
                      <ul>
                        <li>
                          Self-administered fine mist: 1 spray, 1 nostril, 1x a
                          week
                        </li>
                      </ul>
                    </li>
                    <li>
                      Eligible patients may pay as little as $0 per month
                      <sup>†</sup>
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={3}>
                  <img src={nascobalSprayProduct} />
                </Col>
              </Row>
            </ColorBlock>
            <Row>
              <Col xs={12}>
                <p className="footnote strong asterik">
                  <span
                    className="brandname-nascobal"
                    dangerouslySetInnerHTML={{
                      __html: brandNames.NASCOBAL,
                    }}
                  />{' '}
                  Nasal Spray should be administered at least one hour before or
                  one hour after ingestion of hot foods or liquids.
                </p>
                <p className="footnote strong">
                  Dose adjustments may be required.
                </p>
                <p className="footnote dagger">
                  Patients may redeem this offer ONLY when accompanied by a
                  valid prescription. Offer is valid up to a maximum benefit of
                  $150. Offer is not valid for patients whose prescriptions are
                  reimbursed in whole or in part under Medicaid, Medicare,
                  Medigap, VA, DoD, TRICARE, or any other federal or state
                  programs (such as medical assistance programs) or where
                  otherwise prohibited by law. Offer is not valid in VT or where
                  prohibited in whole or in part. This offer may be amended or
                  ended at any time without notice.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/clinical-data/"
                  LinkCaption="VIEW CLINICAL DATA"
                >
                  VIEW CLINICAL DATA
                </BrandCTA>
              </Col>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/how"
                  LinkCaption="SEE HOW TO USE NASCOBAL"
                >
                  SEE HOW TO USE NASCOBAL
                  <sup className="brand-reg-mark">&reg;</sup>
                </BrandCTA>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };
  return (
    <Layout indication={indication} className="hcp-b12options">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default AboutPage;
